import { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Utils
import { rem, responsive } from "../../utils/style";
import metrics from "../../utils/metrics";

// Components
import PageSEO from "../../components/seo/Page";
import GiftingHero from "../../components/gifting/GiftingHero";
import FeaturedAreaNew from "../../components/home/FeaturedArea.New";
import PageHero from "../../components/PageHero";
import Text from "../../components/Text";

// Styled Elements
const GiftingContent = styled.div.attrs({
  className: "d-flex align-items-center justify-content-center flex-column",
})``;

const GiftingHeader = styled.div.attrs({
  className: "mt-5 mt-md-9",
})`
  width: 250px;

  h1 {
    margin: 0;
    text-align: center;
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    font-weight: 500;
    letter-spacing: -0.3px;

    ${responsive.md`
      font-size: ${rem(48)};
      line-height: ${rem(54)};
      letter-spacing: -1.4px;
    `};
  }

  ${responsive.sm`
    width: 800px;
  `};
`;

const FeatureWrapper = styled.div.attrs({
  className: "mb-3 mb-sm-0",
})`
  width: 100%;
  margin-top: 32px;
  ${responsive.md`
    margin-top: 80px;
  `};
`;

export default class GiftPage extends Component {
  constructor(props) {
    super(props);

    let {
      pageTitle,
      pageDescription,
      socialImage,
    } = this.props.data.contentfulGiftPage;

    this.state = {
      seo: {
        pagePath: `gift`,
        title: pageTitle,
        description: pageDescription,
        image: {
          url: socialImage.file.url,
          width: socialImage.file.details.image.width,
          height: socialImage.file.details.image.height,
        },
      },
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Gift Cards",
    });
  }

  trackCtaClick(title, url, e) {
    e.preventDefault();
    metrics
      .track("CTA Clicked", {
        title: title,
        category: "Gift Card",
      })
      .then(() => {
        window.location.href = url;
      });
  }

  render() {
    let {
      heroTitle,
      heroHeadline,
      heroImageDesktop,
      heroImageMobile,
      heroBackgroundColor,
      heroCtaText,
      featuredArea1,
      featuredArea1Image,
      featuredArea2,
      featuredArea2Image,
      featuredArea3,
      featuredArea3Image,
      featuredArea3CtaText,
    } = this.props.data.contentfulGiftPage;

    let checkoutUrl =
      process.env.GATSBY_CHECKOUT_URL || "//checkout.ritual.com";
    const giftCardPersonalizeUrl = `${checkoutUrl}/gift-card/personalize`;

    // `label` represents the CTA text, the rest of the properties should match
    // the props passed to `RitualButton`.
    const heroCtaProps = {
      label: heroCtaText,
      title: heroCtaText,
      onClick: (e) => {
        this.trackCtaClick(heroCtaText, giftCardPersonalizeUrl, e);
      },
    };
    const featuredArea3CtaProps = {
      label: featuredArea3CtaText,
      title: featuredArea3CtaText,
      onClick: (e) => {
        this.trackCtaClick(featuredArea3CtaText, giftCardPersonalizeUrl, e);
      },
    };

    return (
      <>
        <PageSEO {...this.state.seo} />
        <PageHero
          id="gifting"
          backgroundColor={heroBackgroundColor}
          imageMobile={heroImageMobile}
          imageDesktop={heroImageDesktop}
        >
          <GiftingHero
            title={heroTitle}
            headline={heroHeadline}
            cta={heroCtaProps}
          />
        </PageHero>
        <GiftingContent>
          <GiftingHeader>
            <h1>
              <Text
                id="gift.heading"
                defaultMessage="Give Them Something Good"
              />
            </h1>
          </GiftingHeader>
          <FeatureWrapper>
            <FeaturedAreaNew
              align="right"
              image={featuredArea1Image}
              markdown={featuredArea1.childMarkdownRemark.html}
              centerMobile={true}
            />
            <FeaturedAreaNew
              align="left"
              image={featuredArea2Image}
              markdown={featuredArea2.childMarkdownRemark.html}
              centerMobile={true}
            />
            <FeaturedAreaNew
              align="right"
              image={featuredArea3Image}
              markdown={featuredArea3.childMarkdownRemark.html}
              cta={featuredArea3CtaProps}
              centerMobile={true}
            />
          </FeatureWrapper>
        </GiftingContent>
      </>
    );
  }
}

export const giftQuery = graphql`
  query GiftQuery($locale: String!) {
    contentfulGiftPage(node_locale: { eq: $locale }) {
      heroTitle {
        childMarkdownRemark {
          html
        }
      }
      heroHeadline {
        childMarkdownRemark {
          html
        }
      }
      heroImageDesktop {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1920
          quality: 90
        )
        description
      }
      heroImageMobile {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 912
          quality: 90
        )
        description
      }
      heroBackgroundColor
      heroCtaText
      featuredArea1 {
        childMarkdownRemark {
          html
        }
      }
      featuredArea1Image {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 710
          quality: 90
        )
        description
      }
      featuredArea2 {
        childMarkdownRemark {
          html
        }
      }
      featuredArea2Image {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 710
          quality: 90
        )
        description
      }
      featuredArea3 {
        childMarkdownRemark {
          html
        }
      }
      featuredArea3Image {
        file {
          url
        }
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 710
          quality: 90
        )
        description
      }
      featuredArea3CtaText
      pageTitle
      pageDescription
      socialImage {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
    }
  }
`;
